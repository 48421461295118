<template>
  <v-app>
    <div v-if="this.signedIn" class="wrapper">

      <div class="home" v-if="!$vuetify.breakpoint.xs">
        <div class="lessonHeader">
          <h1>User Settings</h1>
          <h2 class="ma-0">Hello, {{ this.username }}</h2>
        </div>
      </div>

      <div class="greetingContainerMobile" v-if="$vuetify.breakpoint.xs">
        <h1>User Settings</h1>
        <h2 class="ma-0">Hello, {{ this.username }}</h2>
      </div>

      <v-overlay :absolute="absolute" :value="overlay" color="#fff" opacity="1">
        <v-card class="mx-auto mb-4" max-width="344" color="white" elevation="0">
          <div class="grid-container">
            <div style="font-size:6em;">
              &#128557;
            </div>
            <div>
              <v-card-text>
                <h3 class="ma-6">
                  Are you sure?
                </h3>
                <div class="text--primary">
                  This cannot be undone.
                </div>
              </v-card-text>
            </div>
            <div>
              <v-btn dark x-large color="primary" @click="overlay = false">
                Cancel
              </v-btn>
              <v-btn class="ma-6" dark color="#90be6d" @click="deleteAccount()">
                Yes, delete my account
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-overlay>

      <div class="contentContainer">
        <ul>
          <li>Download my personal data:<br>
            <v-btn class="ma-2" color="#dedede" @click="downloadCSV()">
              <v-icon dark left> mdi-download </v-icon>
              Export as .csv file
            </v-btn></li>

          <li>Delete my account:<br>
            <v-btn class="ma-2" color="#dedede" @click="overlay = true">
              <v-icon dark left> mdi-delete </v-icon>
              Delete my account
            </v-btn></li>
        </ul>
      </div>

    </div>
  </v-app>
</template>

<style scoped>
  .wrapper {
    background-color: #f8f9fa;
    min-height: 100%;
  }

  #nav {
    margin: 0;
    padding: 0 0 20px 0;
  }

  .lessonHeader {
    color: #fff;
    max-width: 1000px;
    font-size: 1.2em;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: calc(50vw - 500px)
  }

  .home {
    height: 100px;
    text-align: left;
    background-color: #383838;
    background-size: 160px;
    background-repeat: no-repeat;
    background-position: calc(50vw + 320px) 0px;
    position: relative;
    align-content: center;
  }

  .contentContainer {
    width: 100%;
    max-width: 1000px;
    text-align: left;
    padding: 40px 0 0 0;
    margin: auto;
  }

  .grid-container {
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 5px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 1);
    border: 5px solid rgba(0, 0, 0, 1);
    text-align: center;
  }

  .card {
    width: calc(50% - 20px);
    margin: 10px;
    height: 100%;
  }

  .nounsInfo {
    background-color: #ffbe0b;
  }

  .adjectivesInfo {
    background-color: #ada7c9;
  }

  .task2Info {
    background-color: #90be6d;
  }

  .grid-item p {
    padding: 20px 0 0 0;
  }

  .hideOnIndex {
    display: none;
  }
  h1 {
    font-size: 2em;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 1em;
  }

  h3 {
    color: #000;
    font-size: 2em;
    margin: 0;
    padding: 0;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto;
    padding: 0px 20px;
  }

  .grid-container > div {
    text-align: center;
    align-content: center;
    justify-content: center;
  }

</style>




<style scoped>
  @media screen and (max-width: 1024px) {

    h1 {
      font-size: 2em;
      margin-bottom: 20px;
    }

    .greetingContainerMobile {
      height: auto;
      background-color: #383838;
      color: #fff;
      text-align: left;
      padding: 10px;
    }

    .contentContainer {
      width: 100vw;
      max-width: 100vw;
      text-align: left;
      padding: 20px 10px;
      margin: auto;
    }


  }

</style>

<script>
import {db} from '../firebase/db';
import firebase from 'firebase';


export default {
  title: "Settings",
  data() {
    return {
      signedIn: false,
      uid: "",
      userData: [],
      absolute: true,
      overlay: false,
      displayName: ""
    }
  },
  methods: {

    async downloadCSV(){
      var userdl = await db.collection('Users').doc(this.uid).get();
      this.userData = userdl.data();
      var data = [
        ["username", this.userData.username]
      ]
      var csv = 'Name,Value\n';
      data.forEach(function(row) {
              csv += row.join(',');
              csv += "\n";
      });
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'my_data.csv';
      hiddenElement.click();
    },

    async deleteAccount() {
      await db.collection('Users').doc(this.uid).delete();

      var stats = await db.collection('Statistics').doc('STATISTICS').get();
      stats = stats.data();

      stats.numberOfUsers--;

      await db.collection('Statistics').doc('STATISTICS').update({
        numberOfUsers: stats.numberOfUsers
      });

      this.googleSignOut();

      this.$router.push({ name: 'Index'})
    },


    googleSignOut(){
      firebase.auth().signOut().then(() => {
        // alert("Signed Out!");
        this.$router.push('/');
        console.log("Signed out")
      }).catch((error) => {
        console.log(error);
      });

      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          this.uid = user.uid;
          this.displayName = user.displayName;
          this.email = user.email;
          this.photoURL = user.photoURL;
          this.emailVerified = user.emailVerified;
          this.signedIn = true;
        } else {
          // User is signed out
          // ...
          this.signedIn = false;
        }
      });
    }

  },
  async beforeMount() {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.user = user;
        this.uid = user.uid;
        this.username = user.displayName;
        this.signedIn = true

      } else {
        // User is signed out
        // ...
        this.signedIn = false;
        this.$router.push({ name: 'Index'})
      }
    });




    // var userdl = await db.collection('Users').doc('bVFkORS247Pt63XiUqRqgo5o85u1').get();
    // this.userData = userdl.data();

    var stats = await db.collection('Statistics').doc('STATISTICS').get();
    this.stats = stats.data();

    this.nounGenderQuantities = [this.stats.numberOfMasculineNouns, this.stats.numberOfFeminineNouns, this.stats.numberOfNeuterNouns]
    this.componentKey += 1;




  }
}
</script>










<!--  -->
